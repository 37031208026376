<template>
  <div class="container content">
    <div class="headline"><h1>{{ $t('message.companies.header') }}</h1></div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.companies.add.header') }}</p>
          <b-field :label="$t('message.register-company.add.company.name')">
              <b-input v-model="newCompany.name"></b-input>
          </b-field>

           <b-field :label="$t('message.register-company.add.company.email')">
              <b-input type="email" value="" v-model="newCompany.email"></b-input>
           </b-field>

           <b-field :label="$t('message.register-company.add.company.phone')">
              <b-input v-model="newCompany.mobile"></b-input>
           </b-field>

           <b-field :label="$t('message.register-company.add.company.address')">
              <b-input v-model="newCompany.address"></b-input>
           </b-field>
            
            <b-field :label="$t('message.register-company.add.company.postcode')">
              <b-input v-model="newCompany.zip"></b-input>
           </b-field>

            <b-field :label="$t('message.register-company.add.company.city')">
              <b-input v-model="newCompany.city"></b-input>
           </b-field>

            <b-field :label="$t('message.register-company.add.company.business-id')">
              <b-input v-model="newCompany.vat"></b-input>
           </b-field>

            <b-field :label="$t('message.register-company.add.company.homepage')">
              <b-input v-model="newCompany.www"></b-input>
           </b-field>

           <b-field :label="$t('message.register-company.add.company.billing-type.header')">
                <b-select
                    placeholder="Valitse"
                    v-model="newCompany.billing_type">
                    <option value="paper">
                        {{ $t("message.register-company.add.company.billing-type.paper")}}
                    </option>
                    <option value="e-invoice">
                        {{ $t("message.register-company.add.company.billing-type.e-invoice")}}
                    </option>
                </b-select>
            </b-field>


            <b-field :label="$t('message.register-company.add.company.e-invoice-address')">
                <b-input
                    v-model="newCompany.e_invoice_address"
                ></b-input>
            </b-field>
            <b-field :label="$t('message.register-company.add.company.e-invoice-provider')">
                <b-input
                    v-model="newCompany.e_invoice_provider"
                ></b-input>
            </b-field>
            <label class="label">{{$t("message.register-company.add.company.logo.header")}}</label>
            <b-field class="file is-primary" :class="{ 'has-name': !!newCompany.logo.name }">
                <b-upload
                    v-model="newCompany.logo"
                    class="file-label">
                    <span class="file-cta">
                        <b-icon
                            class="file-icon"
                            icon="upload"
                        ></b-icon>
                        <span class="file-label">{{ $t( "message.register-company.add.company.logo.load-here" ) }}</span>
                    </span>
                    <span
                        class="file-name"
                        v-if="newCompany.logo.name">
                        {{ newCompany.logo.name }}
                    </span>
                </b-upload>
            </b-field>

          <b-button
            label="Lisää yritys"
            type="is-primary"
            v-on:click="register" />
        </article>
     </div>
     <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.companies.list.header') }}</p>
           <b-table
                :data="companies"
                :columns="columns">
          </b-table>      
        </article>
     </div>
    </div>  
  </div>
</template>

<script>
export default {
        data() {
            return {
                newCompany: {
                    name: "",
                    address: "",
                    email: "",
                    mobile: "",
                    zip: "",
                    city: "",
                    vat: "",
                    logo: {},
                    www: "",
                    e_invoice_address: "",
                    e_invoice_provider: "",
                    billing_type: "",
                },
                columns: [
                    {
                        field: 'name',
                        label: this.$t('message.companies.list.name')
                    },
                    {
                        field: 'email',
                        label: this.$t('message.companies.list.email')
                    },
                    {
                        field: 'mobile',
                        label: this.$t('message.companies.list.mobile')
                    },
                ]
            }
        },
        methods: {
            register() {
                console.log("adding ... ")
                if (this.newCompany.name == "") {
                    this.toastFailure(this.$t('message.error.company.name'))
                    return
                } 
                if (this.newCompany.email == "") {
                    this.toastFailure(this.$t('message.error.company.email'))
                    return
                } 
                if (this.newCompany.vat == "") {
                    this.toastFailure(this.$t('message.error.company.vat'))
                    return
                } 

                this.$store.dispatch("validateCompany", this.newCompany).then((_response) => {
                    console.log(_response)
                    this.$store.dispatch("createCompany", this.newCompany).then((response) => {
                        var status = response.status;
                        if (status >= 200 && status < 300) {
                                this.toastSuccess(this.$t("message.register-company.toaster.company.created"));
                                this.$store.dispatch("fetchCompanies");
                                
                                this.newCompany.name = "";
                                this.newCompany.address = "";
                                this.newCompany.email = "";
                                this.newCompany.mobile = "";
                                this.newCompany.zip = "";
                                this.newCompany.city = "";
                                this.newCompany.vat = "";
                                this.newCompany.logo = {};
                                this.newCompany.www = "";
                                this.newCompany.e_invoice_address = "";
                                this.newCompany.e_invoice_provider = "";
                                this.newCompany.billing_type = "";
                            } else {
                                console.log(response, response.message)
                                if (response.message.startsWith("SQLSTATE[23000]")) {
                                    this.toastFailure(this.$t("message.error.vat-number-exists"));
                                } else {
                                    this.toastFailure(this.$t("message.register-company.toaster.company.creationError"));
                                }
                            }
                        })
                        .catch((error) => {
                            var status = error.response.status
                            var msg = error.response.data.message
                            var displayed = false

                            if (status == 500) {
                                if (msg.includes("Duplicate entry") && msg.includes("companies_vat_unique")) {
                                    this.toastFailure(this.$t("message.error.vat-number-exists"));
                                    displayed = true
                                }
                            }
                            
                            if (!displayed) {
                                this.toastFailure(this.$t("message.register-company.toaster.company.creationError"));
                            }
                        });
                    }).catch((error) => {
                        var status = error.response.status
                        var msg = error.response.data.message
                        var displayed = false

                        if ('errors' in error.response.data) {
                            var errors = error.response.data.errors 
                            for (const [key, value] of Object.entries(errors)) {
                                console.log(`error -- ${key}: ${value}`);
                                this.toastFailure(this.$t("message.register-company.toaster.error." + key));
                                displayed = true
                            }
                        }

                        if (!displayed) {
                            if (status == 500) {
                                if (msg.includes("Duplicate entry") && msg.includes("companies_vat_unique")) {
                                    this.toastFailure(this.$t("message.error.vat-number-exists"));
                                    displayed = true
                                }
                            }
                            this.toastFailure(this.$t("message.register-company.toaster.company.creationError"));
                        }

                        if (!displayed) {
                            this.toastFailure(this.$t("message.register-company.toaster.company.creationError"));
                        }
                    });
            },
        },
        computed: {
            companies() {
                return this.$store.getters['getCompanies']
            }
        },
        mounted() {
            this.$store.dispatch("fetchCompanies");
        }
    }
</script>

